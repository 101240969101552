import {Component, OnInit} from '@angular/core';
import {DataService, FormInputComponent, SharedModule} from '@vendure/admin-ui/core';
import {Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {RelationCustomFieldConfig} from "@vendure/core";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'channel-assignment-input',
    template: `
        <select [formControl]="formControl" [compareWith]="compareFn">
            <option [ngValue]="null">Select a channel...</option>
            <option *ngFor="let channel of channels | async" [ngValue]="channel">
                {{ channel.code | channelCodeToLabel | translate }}
            </option>
        </select>
    `,
    standalone: true,
    imports: [SharedModule],
})
export class ChannelAssignmentInputComponent implements OnInit, FormInputComponent<RelationCustomFieldConfig> {
    readonly: boolean;
    config: RelationCustomFieldConfig;
    formControl: FormControl;

    channels: Observable<any[]>

    constructor(private dataService: DataService, private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.channels = this.dataService.client.userStatus().mapStream(data => data.userStatus.channels);
    }

    compareFn(item1: { id: string } | null, item2: { id: string } | null) {
        return item1 && item2 ? item1.id === item2.id : item1 === item2;
    }
}

import { addActionBarItem } from '@vendure/admin-ui/core';

export default [
    addActionBarItem({
        id: 'create-store-user-button-customer',
        label: 'customer.create-new-store',
        locationId: 'customer-list',
        buttonColor: 'success',
        icon: 'store',
        requiresPermission: 'CreateCustomer',
        routerLink: ['/extensions/create-store'],
    }),

    addActionBarItem({
        id: 'create-store-user-button-customer-group',
        label: 'customer.create-new-store',
        locationId: 'customer-group-list',
        buttonColor: 'success',
        icon: 'store',
        requiresPermission: 'CreateCustomer',
        routerLink: ['/extensions/create-store'],
    }),
];